import { Home, Signup, Login, StripeCallback } from './pages';
import DashboardRoutes from './DashboardRoutes';

export const publicRoutes = [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/login',
    exact: false,
    component: Login,
  },
  {
    path: '/signup',
    exact: false,
    component: Signup,
  },
];

export const privateRoutes = [
  { path: '/dashboard', exact: false, component: DashboardRoutes },
  {
    path: '/stripe-callback',
    exact: false,
    component: StripeCallback,
  },
];
