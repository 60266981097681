import React from 'react';
import { ASSETS_CDN } from 'config/assets';
import FeatureIcon from 'assets/images/featureIcon.png';

const Feature = () => (
  <div className="Feature">
    <div className="row">
      <div className="col-md-6">
        <div className="feature-mobile">
          <img
            className="img-fluid"
            src={`${ASSETS_CDN}/featuremobile.png`}
            alt="FeatureMobile"
            rel="preload"
            as="image"
          />
        </div>
      </div>
      <div className="col-xl-5 col-lg-5 col-md-5 feature-main">
        <h2>FEATURES</h2>
        <ul>
          <li>
            <img src={FeatureIcon} alt="FeatureIcon" />
            <p>
              Secure payment functionality to collect donations powered by a leading PCI-certified gateway
              which has been credited with a PCI Service Provider Level 1 certification. This is the most
              stringent level of certification available in the payments industry.
            </p>
          </li>
          <li>
            <img src={FeatureIcon} alt="FeatureIcon" />
            <p>
              Access to all of your church groups/ministries with an easy join and approval process to help
              facilitate the different areas of your church.
            </p>
          </li>
          <li>
            <img src={FeatureIcon} alt="FeatureIcon" />
            <p>
              Create, manage and RSVP to events whether it be for the entire church community or a closed
              group. This all-encompassing solution will keep your events in one place without sacrificing
              functionality.
            </p>
          </li>
          <li>
            <img src={FeatureIcon} alt="FeatureIcon" />
            <p>Much, much more!!!</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Feature;
