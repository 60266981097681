import React from 'react';
import GooglePlayBadge from 'assets/images/google-play-badge.svg';
import AppleAppStoreBadge from 'assets/images/apple-app-store-badge.svg';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Typography } from '@material-ui/core';
import TrademarkFooter from '../../components/TrademarkFooter';

const Footer = () => (
  <div className="footer">
    <div className="row p-1 flex-column-reverse flex-lg-row">
      <div className="col d-flex flex-column flex-lg-row align-items-center justify-content-around">
        <div
          className="social-media d-flex flex-column flex-lg-row justify-content-between align-items-center"
          style={{ width: '66%' }}
        >
          <Typography variant="h6" align="center">
            Checkout us on our Social Media Pages
          </Typography>
          <div className="logos d-flex justify-content-between w-100">
            <div className="logo d-flex flex-column flex-xl-row align-items-center">
              <InstagramIcon color="secondary" style={{ fontSize: 60 }} />
              <span>@FaithMoApp</span>
            </div>
            <div className="logo d-flex flex-column flex-xl-row align-items-center">
              {/* <img src={FacebookIcon} alt="Facebook" /> */}
              <FacebookIcon color="secondary" style={{ fontSize: 60 }} />
              <span>@FaithMo</span>
            </div>
            <div className="logo d-flex flex-column flex-xl-row align-items-center">
              <TwitterIcon color="secondary" style={{ fontSize: 60 }} />
              <span>@FaithMo</span>
            </div>
          </div>
        </div>
        <div className="FaithmoAuth__badges">
          <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
            <img src={GooglePlayBadge} alt="Google Play" className="FaithmoAuth__badge" />
          </a>
          <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
            <img src={AppleAppStoreBadge} alt="Apple App Store" className="FaithmoAuth__badge" />
          </a>
        </div>
      </div>
      <Typography variant="body1" className="summary">
        Faithmo is about staying connected with your church home or ministry wherever or whenever. Out of
        our strongest core beliefs is that we help unify the church body by offering easy to use solutions
        in areas of impact. The team at Faithmo is dedicated to providing a top-tier platform with a
        standard of excellence.
      </Typography>
    </div>
    <TrademarkFooter />
  </div>
);

export default Footer;
