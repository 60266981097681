import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ASSETS_CDN } from 'config/assets';
import StyledModal from 'components/StyledModal';

import Logo from 'assets/images/Logo.png';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // openUser: false,
      openChurch: false,
    };
  }

  // onOpenUserModal = () => {
  //   this.setState({ openUser: true });
  // };

  // onCloseUserModal = () => {
  //   this.setState({ openUser: false });
  // };

  onOpenChurchModal = () => {
    this.setState({ openChurch: true });
  };

  onCloseChurchModal = () => {
    this.setState({ openChurch: false });
  };

  render() {
    const { openChurch } = this.state;
    const { location } = this.props;

    return (
      <div
        className="header"
        style={{
          backgroundImage: `url(${ASSETS_CDN}/background.jpg)`,
        }}
      >
        <div className="header-content-top">
          <img className="logo" src={Logo} alt="Logo" />
          <Link
            className="button neutral medium login-btn"
            to={{
              pathname: '/login',
              state: { from: location.pathname },
            }}
          >
            Login
          </Link>
        </div>
        <div className="header-content-main">
          <h1 className="title--main">Pray, Give</h1>
          <p className="title-info--main">
            and stay up to date<span>!</span>
          </p>
        </div>
        <div className="header-content-bottom">
          {/* <button */}
          {/*  type="button" */}
          {/*  className="button lightblue margin--r" */}
          {/*  // hidden */}
          {/*  onClick={this.onOpenUserModal} */}
          {/* > */}
          {/*  User signup */}
          {/* </button> */}
          <button type="button" className="button red" onClick={this.onOpenChurchModal}>
            Church signup
          </button>
          <StyledModal
            open={openChurch}
            onClose={this.onCloseChurchModal}
            maxWidth="md"
            fullWidth
            className="header-signup-modal"
            style={{
              backgroundImage: `url(${ASSETS_CDN}/background.jpg)`,
              minHeight: 372,
            }}
            padding={0}
          >
            <div className="layer--dark" />
            <h6 className="header-title" style={{ zIndex: 1 }}>
              Let&apos;s sign you up! Please complete all 3 steps.
            </h6>
            <Link
              className="button white-font"
              style={{ zIndex: 1 }}
              to={{
                pathname: '/signup',
                state: {
                  isUserForm: false,
                  from: location.pathname,
                },
              }}
            >
              Get started
            </Link>
          </StyledModal>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Header);
