import aws from 'aws-sdk';

aws.config.credentials = new aws.Credentials(
  process.env.REACT_APP_EMAILER_ID,
  process.env.REACT_APP_EMAILER_SECRET,
);

export const SNS = new aws.SNS({
  apiVersion: process.env.REACT_APP_EMAILER_SNS_API_VERSION,
  region: process.env.REACT_APP_REGION,
});

export default {
  SNS,
};
