import React from 'react';
import { ASSETS_CDN } from 'config/assets';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';

const Available = () => (
  <div className="Available">
    <div className="discription-part">
      <h2>Available for:</h2>
      <div className="icons-style">
        <AppleIcon fontSize="inherit" />
        <span className="and-sign">&amp;</span>
        <AndroidIcon fontSize="inherit" />
      </div>
      <p>
        We offer both an Android and iOS mobile application that provides your congregation access to all
        things happening at your church. Whether a member wants to give tithes and offering or wants to know
        what the upcoming events are, the Faithmo app puts the power in their hands.
      </p>
    </div>
    <div className="available-mobile">
      <img
        className="img-fluid"
        src={`${ASSETS_CDN}/availablemobile.png`}
        alt="AvailableMobile"
        rel="preload"
        as="image"
      />
    </div>
  </div>
);

export default Available;
