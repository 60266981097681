import React, { useState } from 'react';
import MainImg1 from 'assets/images/main-image1.png';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Available from './Available';
import Feature from './Feature';
import { ASSETS_CDN } from '../../config/assets';
import StyledModal from '../../components/StyledModal';

const Main = ({ location }) => {
  const [openChurch, setOpenChurch] = useState(false);
  return (
    <div className="main">
      <div className="main-content1">
        <div className="main-title">
          <h1>Faithmo is the Church CRM solution that grows with your ministry.</h1>
          <p>
            Whether you are on a desktop, laptop or tablet, access the Faithmo platform and let it adjust to
            your workspace. We use top-tier user-interface, payment gateway, and data storage technologies
            to provide a dynamic and timeless solution for your church.
          </p>
        </div>
        <div className="mainimg">
          <img className="img-fluid" src={MainImg1} alt="MainImg1" />
        </div>
        <hr />
        <div className="main-bottom">
          <h1>Sign up for a no-risk free trial today.</h1>
          <button type="button" className="button red medium" onClick={() => setOpenChurch(true)}>
            try faithmo for free
          </button>
          <hr />
          <p>What to know more?</p>
          <div className="contact">
            <div className="contact-block">
              <span className="contact-text">Give us a call at</span>
              <span className="contact-value">(313) 312-9345</span>
            </div>
            <div className="contact-block">
              <span className="contact-text">Send your question to </span>
              <span className="contact-value">info@fathmo.com</span>
            </div>
          </div>
        </div>
      </div>
      <Available />
      <Feature />
      <StyledModal
        open={openChurch}
        onClose={() => setOpenChurch(false)}
        maxWidth="md"
        fullWidth
        className="header-signup-modal"
        style={{
          backgroundImage: `url(${ASSETS_CDN}/background.jpg)`,
          minHeight: 372,
        }}
        padding={0}
      >
        <div className="layer--dark" />
        <h6 className="header-title" style={{ zIndex: 1 }}>
          Let&apos;s sign you up! Please complete all 31 steps.
        </h6>
        <Link
          className="button white-font"
          style={{ zIndex: 1, color: 'white !important' }}
          to={{
            pathname: '/signup',
            state: {
              isUserForm: false,
              from: location.pathname,
            },
          }}
        >
          Get started
        </Link>
      </StyledModal>
    </div>
  );
};

Main.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Main);
