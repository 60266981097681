import React, { PureComponent } from 'react';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import './style.css';

class Home extends PureComponent {
  render() {
    return (
      <div className="home">
        <Header />
        <Main />
        <Footer />
      </div>
    );
  }
}

export default Home;
